import { where, orderBy, limit } from 'firebase/firestore'
import {
  DATABASE,
  DEFAULT_HIDDEN_ZEBRA_APPS,
  DEFAULT_ZEBRA_APPS,
  DEVICE_USERS_EXPIRY_CAL,
} from '../../constants/Constant'
import { updateDeviceUsers } from '../cloudFunction/index'
import { getDocument, getMultipleDocument, setDocument } from './firebaseCliDaoMethods'

function getDBCollectionDeviceUsers(tenantId) {
  return `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICE_USERS_COL}`
}

const getDeviceUsersStatus = (tenantId) => {
  return new Promise((resolve) => {
    let deviceUsersRef = getDBCollectionDeviceUsers(tenantId)
    let conditions = []
    conditions.push(limit(5))
    getMultipleDocument(deviceUsersRef, conditions)
      .then((snapshot) => {
        const flag = snapshot.docs.length ? true : false
        resolve(flag)
      })
      .catch((e) => {
        resolve(false)
      })
  })
}

const getDeviceUsersListByFilters = async (tenantId, filters = {}) => {
  return new Promise((resolve, reject) => {
    console.log('Inside getDeviceUsersListByFilters')
    let docData = []
    let dbCollectionDevices = getDBCollectionDeviceUsers(tenantId)
    let conditions = []
    if (filters) {
      let keys = Object.keys(filters)
      keys.forEach((key) => {
        let condition = '=='
        let val = filters[key].toUpperCase()
        console.log(`Adding key ${key}:${filters[key]} to query`)
        conditions.push(where(key, condition, val))
      })
    }
    conditions.push(orderBy('createdOn', 'desc'))

    getMultipleDocument(dbCollectionDevices, conditions)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists && doc.data()) {
            let obj = doc.data()
            if (doc.id === obj.enrollmentId) {
              docData.push({ id: doc.id, ...obj })
            }
          }
        })
        resolve(docData)
      })
      .catch((e) => {
        console.log('Get device list by group error msg:', e)
        resolve([])
      })
  })
}

const updateDeviceUsersDetails = (docId, data, tenantId) => {
  console.log('inside updateDeviceUsersDetails')
  const colPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.DEVICE_USERS_COL}`
  return setDocument(colPath, docId, data, true).then((res) => {
    return { result: 'success', data: res }
  })
}

const processDeviceUsersActiveType = (deviceUserList, tenantId) => {
  console.log('inside processDeviceUsersActiveType')
  const colPath = `/${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.TENANT_CONFIG_COL}`
  const timeDiffConst = DEVICE_USERS_EXPIRY_CAL // 1 hour
  return new Promise(function (resolve, reject) {
    // let colRef = db.collection(colPath).doc(DATABASE.DEVICE_USERS_COL)
    getDocument(colPath, DATABASE.DEVICE_USERS_COL)
      .then((snapshot) => {
        const curTime = Date.now()
        if (
          snapshot.exists &&
          snapshot.data() &&
          snapshot.data().lastRefreshTime &&
          curTime - snapshot.data().lastRefreshTime < timeDiffConst
        ) {
          // less than 1 hour
          console.log('Skipping active type calculation')
          return resolve({ success: 'success', data: deviceUserList })
        } else {
          console.log('Calculating active type')
          return updateDeviceUsers(tenantId)
            .then((res) => {
              console.log('updateDeviceUsers call done ')
              if (res?.data && res.data.status && res.data.status === 'success') {
                return resolve({ success: 'success', data: res.data.result })
              } else {
                console.log('Error while updation')
                return resolve({ success: 'error', data: deviceUserList })
              }
            })
            .catch((err) => {
              console.log('Catch error while updating')
              return resolve({ success: 'error', data: deviceUserList })
            })
        }
      })
      .catch((err) => {
        console.log('Error while fetching doc')
        return resolve({ success: 'error', data: deviceUserList })
      })
  })
}

const updateDeviceUsersActiveType = (deviceUserList, tenantId) => {
  console.log('inside updateDeviceUsersActiveType')
  return new Promise((resolve) => {
    let promiseArr = []
    for (const el of deviceUserList) {
      let currentDate = Date.now()
      if (!el.activeType) {
        let type = !el.validThrough
          ? 'Active'
          : currentDate > el.validThrough
          ? 'Expired'
          : 'Active'
        console.log('Type: ', type)
        let finalData = { activeType: type }
        el.activeType = type
        console.log('Enrollmentid adding active type')
        promiseArr.push(updateDeviceUsersDetails(el.id, finalData, tenantId))
      } else if (
        el.activeType &&
        el.activeType !== 'Expired' &&
        el.validThrough &&
        currentDate > el.validThrough
      ) {
        let finalData = { activeType: 'Expired' }
        el.activeType = 'Expired'
        console.log('Enrollmentid expired - time based')
        promiseArr.push(updateDeviceUsersDetails(el.id, finalData, tenantId))
      }
    }
    return Promise.allSettled(promiseArr).then((responseArr) => {
      console.log('inside updateDeviceUsersActiveType, promise')
      let errorArr = []
      responseArr.map((item) => (item.status === 'rejected' ? errorArr.push(item.reason) : null))
      if (!errorArr.length > 0) {
        console.log('inside updateDeviceUsersActiveType, updation done')
        return resolve({ success: 'success', data: deviceUserList })
      } else {
        console.log('inside updateDeviceUsersActiveType, Error: ' + JSON.stringify(errorArr))
        return resolve({ success: 'error', data: [] })
      }
    })
  })
}

function getFeaturePermissionDBDocPath(tenantId) {
  return `${DATABASE.TENANT_COL}/${tenantId}/${DATABASE.TENANT_CONFIG_COL}/${DATABASE.USER_PREFERENCE}/${DATABASE.FEATURE_PERMISSION}/`
}

const zebraCollectionWhiteListedTenantCheck = (tenantId, appDetails) => {
  console.log('inside zebraCollectionWhiteListedTenantCheck')
  console.log('insdie zebraCollectionWhiteListedTenantCheck, appDetails: ', appDetails.length)
  let colPath = getFeaturePermissionDBDocPath(tenantId)
  let finalAppDetails = []
  return new Promise((resolve) => {
    getDocument(colPath, DATABASE.MY_APPS)
      .then((docRef) => {
        const defaultApps = DEFAULT_ZEBRA_APPS
        const optionalApps = DEFAULT_HIDDEN_ZEBRA_APPS
        const displayedApps = []
        if (docRef.exists) {
          let doc = docRef.data()
          const userPreferences = doc && doc.zebraCollection ? doc.zebraCollection : {}

          for (const app of defaultApps) {
            if (userPreferences && userPreferences[app]) {
              if (userPreferences[app] === 'show') {
                displayedApps.push(app)
              }
            } else {
              displayedApps.push(app)
            }
          }

          for (const app of optionalApps) {
            if (userPreferences && userPreferences[app] && userPreferences[app] === 'show') {
              displayedApps.push(app)
            }
          }

          finalAppDetails = appDetails.filter((obj) => displayedApps.includes(obj.appPackage))
          console.log(
            'inside zebraCollectionWhiteListedTenantCheck, filtered list to display: ',
            finalAppDetails.length
          )
          return resolve(finalAppDetails)
        } else {
          console.log('inside zebraCollectionWhiteListedTenantCheck, no doc found')
          finalAppDetails = appDetails.filter((obj) => !optionalApps.includes(obj.appPackage))
          console.log('Filtered list: ', finalAppDetails.length)
          return resolve(finalAppDetails)
        }
      })
      .catch((e) => {
        console.log('Error while performing whitelisted tenant check error msg:', e)
        return resolve([])
      })
  })
}

export {
  getDeviceUsersListByFilters,
  updateDeviceUsersActiveType,
  updateDeviceUsersDetails,
  zebraCollectionWhiteListedTenantCheck,
  processDeviceUsersActiveType,
  getDeviceUsersStatus,
}
